<template>
  <div>
    <b-card class="screenAlignmentDataTable">
      <div>
        <div class="schedule-hearder">
          <div>
            <b-card-title>
            <h3 class="app-title mdsm">{{ title }}</h3>
          </b-card-title>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <!-- <div v-if="this.type === 'amazonCampaign'" style="display: flex; justify-content: space-between;gap: 10px;">
              <div>
                <b-form-group>
                  <label for="client-name">Category Type: <span class="validation">*</span></label>
                    <b-form-select placeholder="Select client sector"/>
                 </b-form-group>
              </div>
              <div>
                <b-form-group>
                  <label for="client-name">Sub-Category Type: <span class="validation">*</span></label>
                    <b-form-select placeholder="Select client sector"/>
                 </b-form-group>
              </div>
            </div> -->
            <div v-if="this.type === 'monthWiseDataAmazonDashboard' ">
              <div style="display: flex; justify-content: space-between;gap: 10px;">
                <div style="width: 150px;">
                  <b-form-group>
                    <label for="client-name">Sub Category Type:</label>
                      <b-form-select  @input="getSubCatergoryChange"  class="uppercase-options" v-model="selectedSubCategoriesType" :options="this.subCampaign && this.subCampaign.sub_category"/>
                   </b-form-group>
                </div>
                <div style="width: 170px;">
                  <b-form-group>
                    <label for="client-name">Sub Sub Category Type:</label>
                      <b-form-select  @input="getSubSubCatergoryChange"  class="uppercase-options" v-model="selectedSubSubCategoriesType" :options="this.subCampaign && this.subCampaign.sub_sub_category"/>
                   </b-form-group>
                </div>
                <div style="width: 130px;">
                  <b-form-group>
                    <label for="client-name">BCG:</label>
                      <b-form-select @input="getBCGCatergoryChange"   class="uppercase-options" v-model="selectedBcg" :options="this.subCampaign && this.subCampaign.bcg"/>
                   </b-form-group>
                </div>
                <div style="width: 130px;">
                  <b-form-group>
                    <label for="client-name">Ad Type:</label>
                      <b-form-select  @input="getAdTypeCatergoryChange"   class="uppercase-options" v-model="selectedAdType" :options="this.subCampaign && this.subCampaign.ad_type"/>
                   </b-form-group>
                </div>
              </div>
            </div>
            <div v-if="this.type === 'amazon' ">
              <div style="display: flex; justify-content: space-between;gap: 10px;">
                <div style="width: 150px;">
                  <b-form-group>
                    <label for="client-name">Sub Category Type:</label>
                      <b-form-select  @input="getAmazonSubCatergoryChange"  class="uppercase-options" v-model="selectedAmazonSubCategoriesType" :options="this.subCampaign && this.subCampaign.sub_category"/>
                   </b-form-group>
                </div>
                <div style="width: 170px;">
                  <b-form-group>
                    <label for="client-name">Sub Sub Category Type:</label>
                      <b-form-select  @input="getAmazonSubSubCatergoryChange"  class="uppercase-options" v-model="selectedAmazonSubSubCategoriesType" :options="this.subCampaign && this.subCampaign.sub_sub_category"/>
                   </b-form-group>
                </div>
                <div style="width: 130px;">
                  <b-form-group>
                    <label for="client-name">BCG:</label>
                      <b-form-select @input="getAmazonBCGCatergoryChange"   class="uppercase-options" v-model="selectedAmazonBcg" :options="this.subCampaign && this.subCampaign.bcg"/>
                   </b-form-group>
                </div>
                <div style="width: 130px;">
                  <b-form-group>
                    <label for="client-name">Ad Type:</label>
                      <b-form-select  @input="getAmazonAdTypeCatergoryChange"   class="uppercase-options" v-model="selectedAmazonAdType" :options="this.subCampaign && this.subCampaign.ad_type"/>
                   </b-form-group>
                </div>
              </div>
            </div>
            <input
            v-if="this.type === 'amazonCampaign' || this.type === 'facebook' || this.type === 'campaign'"
            type="text"
            style="width: 300px; margin-top:23px"
            v-model="searchTerm"
            placeholder="Search by Campaign name"
          />
            <b-button  @click="exportData" class="exportButton"  size="sm" variant="primary" style=" margin-top:23px"
            ><span style="font-weight: bold;">
              Export Data
              <feather-icon
                icon="DownloadIcon"
                size="15"
                style="margin-left: 5px;"/></span
          ></b-button>
          </div>
        </div>
      </div>
      <div v-if="this.isTableDataLoad" class="spinnerCentered">
        <multiChannelSpinner />
      </div>
      <div v-else>
        <vue-good-table
        class="custom-data-table mainTable"
        :columns="tableColumns"
        :rows="chartData"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
      >
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '7', '10']"
                class="mx-1"
                @input="
                  value => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  currentPage => props.pageChanged({ currentPage: currentPage })
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
        </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardTitle,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import commaNumber from "comma-number";
import millify from "millify";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import { sortFuntionStringintoNumericVal, sortFuntionNumericVal } from "@/components/client/SortingFunction.js";

export default {
  data() {
    return {
      pageLength: 7,
      searchTerm: "",
      currentPage: 1,
      selectedSubCategoriesType:"all",
      selectedSubSubCategoriesType:"all",
      selectedBcg:"all",
      selectedAdType:"all",
      selectedAmazonSubCategoriesType:"all",
      selectedAmazonSubSubCategoriesType:"all",
      selectedAmazonBcg:"all",
      selectedAmazonAdType:"all",
    };
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardTitle,
    BButton,
    multiChannelSpinner
  },
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    businessType:{
      type: String
    },
    type: {
      type: String,
      default: "date"
    },
    title: {
      type: String
    },
    exportsType:{
      type: String
    },
    subCampaign:{
        type:Object
      },
    isTableDataLoad:{
        type:Boolean 
      },
      
  },
  watch: {
    chartData(newVal, oldVal) {
      // this.chartData = newVal
      this.currentPage = 1;
    }
  },
  computed: {
    tableColumns() {
      if (this.type === "date") {
        if (this.businessType == 'leadgen'){
          let columns = [
          {
            label: "Date",
            field: "date",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px"
          },
          {
            label: "Cost",
            field: "costMicros",
            sortFn: this.sortFn
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn
          },
          {
            label: "Leads",
            field: "conversions",
            sortFn: this.sortFn
          },
          // {
          //   label: "Revenue",
          //   field: "allConversionsValue",
          //   sortFn: this.sortFn
          // },
          {
            label: "Conversion Rate %",
            field: "conversionRate",
            sortFn: this.sortFn
          },
          {
            label: "CAC",
            field: "cpc"
          },
          {
            label: "Avg. CPM",
            field: "avg_cpm"
          },
          // {
          //   label: "CPC",
          //   field: "cpc",
          // },
          {
            label: "Avg. CPC",
            field: "cpc"
          }
        ]
        return columns;
        }else if(this.businessType == 'ecommerce'){
          let columns = [
          {
            label: "Date",
            field: "date",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px"
          },
          {
            label: "Cost",
            field: "costMicros",
            sortFn: this.sortFn
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn
          },
          {
            label: "Purchases",
            field: "conversions",
            sortFn: this.sortFn
          },
          {
            label: "Revenue",
            field: "allConversionsValue",
            sortFn: this.sortFn
          },
          {
            label: "Conversion Rate %",
            field: "conversionRate",
            sortFn: this.sortFn
          },
          {
            label: "CAC",
            field: "cpc"
          },
          {
            label: "Avg. CPM",
            field: "avg_cpm"
          },
          // {
          //   label: "CPC",
          //   field: "cpc",
          // },
          {
            label: "Avg. CPC",
            field: "cpc"
          }
        ];
        return columns;
        }else{
          let columns = [
          {
            label: "Date",
            field: "date",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px"
          },
          {
            label: "Cost",
            field: "costMicros",
            sortFn: this.sortFn
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn
          },
          {
            label: "Conversions",
            field: "conversions",
            sortFn: this.sortFn
          },
          {
            label: "Revenue",
            field: "allConversionsValue",
            sortFn: this.sortFn
          },
          {
            label: "Conversion Rate %",
            field: "conversionRate",
            sortFn: this.sortFn
          },
          {
            label: "CAC",
            field: "cpc"
          },
          {
            label: "Avg. CPM",
            field: "avg_cpm"
          },
          // {
          //   label: "CPC",
          //   field: "cpc",
          // },
          {
            label: "Avg. CPC",
            field: "cpc"
          }
        ];
        return columns;
        }
        let columns = [
          {
            label: "Date",
            field: "date",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px"
          },
          {
            label: "Cost",
            field: "costMicros",
            sortFn: this.sortFn
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn
          },
          {
            label: "Conversions",
            field: "conversions",
            sortFn: this.sortFn
          },
          {
            label: "Revenue",
            field: "allConversionsValue",
            sortFn: this.sortFn
          },
          {
            label: "Conversion Rate %",
            field: "conversionRate",
            sortFn: this.sortFn
          },
          {
            label: "CAC",
            field: "cpc"
          },
          {
            label: "Avg. CPM",
            field: "avg_cpm"
          },
          // {
          //   label: "CPC",
          //   field: "cpc",
          // },
          {
            label: "Avg. CPC",
            field: "cpc"
          }
        ];
        return columns;
      } else if (this.type === "facebook") {
        if (this.businessType == 'leadgen') {
          let columns = [
          {
            label: "Campaign Name",
            field: "campaignName",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px",
            sortable: false
          },
          {
            label: "Spends",
            field: "spends",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Impressions",
            field: "impressions",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Clicks",
            field: "clicks",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "CTR",
            field: "ctr",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Leads",
            field: "conversions",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Cost per Result",
            field: "costPerResult",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          // {
          //   label: "Conversion Value",
          //   field: "conversionValue",
          //   tdClass: "text-center",
          //   thClass: "text-center",
          //   sortFn: this.sortFn
          // },
          {
            label: "ROAS",
            field: "ROAS",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "CVR",
            field: "cvr",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          }
        ];
        return columns;
        }else if(this.businessType == 'ecommerce'){
       let columns = [
          {
            label: "Campaign Name",
            field: "campaignName",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px",
            sortable: false
          },
          {
            label: "Spends",
            field: "spends",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Impressions",
            field: "impressions",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Clicks",
            field: "clicks",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "CTR",
            field: "ctr",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Purchases",
            field: "webPurchases",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Cost per Result",
            field: "costPerResult",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Revenue",
            field: "conversionValue",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "ROAS",
            field: "ROAS",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "CVR",
            field: "cvr",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          }
        ];
        return columns;
        }else{
        let columns = [
          {
            label: "Campaign Name",
            field: "campaignName",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px",
            sortable: false
          },
          {
            label: "Spends",
            field: "spends",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Impressions",
            field: "impressions",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Clicks",
            field: "clicks",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "CTR",
            field: "ctr",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Conversions",
            field: "conversions",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Cost per Result",
            field: "costPerResult",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "Conversion Value",
            field: "conversionValue",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "ROAS",
            field: "ROAS",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          },
          {
            label: "CVR",
            field: "cvr",
            tdClass: "text-center",
            thClass: "text-center",
            sortFn: this.sortFn
          }
        ];
        return columns;
        }  
      } else if (this.type === "campaign") {
        if (this.businessType == 'leadgen') {
          let columns = [
          {
            label: "Campaign",
            field: "campaignName",
            thClass: "campaign-name-th-class",
            sortable: false
          },
          {
            label: "Cost",
            field: "costMicros",
            sortFn: this.sortFn
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn
          },
          {
            label: "Leads",
            field: "conversions",
            sortFn: this.sortFn
          },
          // {
          //   label: "Revenue",
          //   field: "allConversionsValue",
          //   sortFn: this.sortFn
          // },
          {
            label: "Conversion Rate %",
            field: "conversionRate",
            sortFn: this.sortFn
          },
          {
            label: "CAC",
            field: "cpc"
          },
          {
            label: "CTR",
            field: "ctr",
            sortFn: this.sortFn
          },
          {
            label: "Avg. CPM",
            field: "avg_cpm",
            sortFn: this.sortFn
          },
          {
            label: "Avg. CPC",
            field: "cpc",
            sortFn: this.sortFn
          }
        ];
        return columns;
        }
       else if (this.businessType == 'branding') {
          let columns = [
          {
            label: "Campaign",
            field: "campaignName",
            thClass: "campaign-name-th-class",
            sortable: false
          },
          {
            label: "Cost",
            field: "costMicros",
            sortFn: this.sortFn
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn
          },
          {
            label: "Viewable Impressions",
            field: "activeViewImpressions",
            sortFn: this.sortFn
          },
          {
            label: "Views",
            field: "videoViews"
          },
          {
            label: "CPV",
            field: "averageCpv",
            sortFn: this.sortFn
          },
          {
            label: "VTR",
            field: "videoViewRate",
            sortFn: this.sortFn
          },
          {
            label: "Avg. Impr. freq./ user",
            field: "averageImpressionFrequencyPerUser",
            sortFn: this.sortFn
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn
          },
          {
            label: "CTR",
            field: "ctr",
            sortFn: this.sortFn
          },
          {
            label: "Conversion Rate %",
            field: "conversionRate",
            sortFn: this.sortFn
          },
          {
            label: "CAC",
            field: "cpc"
          },
          {
            label: "Avg. CPM",
            field: "avg_cpm",
            sortFn: this.sortFn
          },
          {
            label: "Avg. CPC",
            field: "cpc",
            sortFn: this.sortFn
          },
        ];
        return columns;
        }else if(this.businessType == 'ecommerce'){
          let columns = [
          {
            label: "Campaign",
            field: "campaignName",
            thClass: "campaign-name-th-class",
            sortable: false
          },
          {
            label: "Cost",
            field: "costMicros",
            sortFn: this.sortFn
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn
          },
          {
            label: "Purchases",
            field: "conversions",
            sortFn: this.sortFn
          },
          {
            label: "Revenue",
            field: "allConversionsValue",
            sortFn: this.sortFn
          },
          {
            label: "Conversion Rate %",
            field: "conversionRate",
            sortFn: this.sortFn
          },
          {
            label: "CAC",
            field: "cpc"
          },
          {
            label: "CTR",
            field: "ctr",
            sortFn: this.sortFn
          },
          {
            label: "Avg. CPM",
            field: "avg_cpm",
            sortFn: this.sortFn
          },
          {
            label: "Avg. CPC",
            field: "cpc",
            sortFn: this.sortFn
          }
        ];
          return columns;
        }else{
          let columns = [
          {
            label: "Campaign",
            field: "campaignName",
            thClass: "campaign-name-th-class",
            sortable: false
          },
          {
            label: "Cost",
            field: "costMicros",
            sortFn: this.sortFn
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn
          },
          {
            label: "Conversions",
            field: "conversions",
            sortFn: this.sortFn
          },
          {
            label: "Revenue",
            field: "allConversionsValue",
            sortFn: this.sortFn
          },
          {
            label: "Conversion Rate %",
            field: "conversionRate",
            sortFn: this.sortFn
          },
          {
            label: "CAC",
            field: "cpc"
          },
          {
            label: "CTR",
            field: "ctr",
            sortFn: this.sortFn
          },
          {
            label: "Avg. CPM",
            field: "avg_cpm",
            sortFn: this.sortFn
          },
          {
            label: "Avg. CPC",
            field: "cpc",
            sortFn: this.sortFn
          }
        ];
          return columns;
        }    
      } else if (this.type === "amazonCampaign") {
        let columns = [
          {
            label: "Product Name",
            field: "key",
            thClass: "campaign-name-th-class",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px"
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Spends",
            field: "spends",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Sales",
            field: "sales",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Orders",
            field: "orders",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CTR",
            field: "ctr",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CPC",
            field: "costPerClick",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CVR",
            field: "conversionRate",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "ACOS",
            field: "acos",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "ROAS",
            field: "roas",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "AOV",
            field: "aov",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          }
        ];
        return columns;
      } else if (this.type === "amazon") {
        let columns = [
          {
            label: "Date",
            field: "key",
            thClass: "campaign-name-th-class",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px"
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Spends",
            field: "spends",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Sales",
            field: "sales",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Orders",
            field: "orders",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CTR",
            field: "ctr",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CPC",
            field: "costPerClick",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CVR",
            field: "conversionRate",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "ACOS",
            field: "acos",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "ROAS",
            field: "roas",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "AOV",
            field: "aov",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          }
        ];

        return columns;
      } else if (this.type === "amazonPlannedVsAchieved") {
        let columns = [
          {
            label: "Date",
            field: "key",
            thClass: "campaign-name-th-class",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px"
          },
          {
            label: "Planned Sales",
            field: "plannedSales",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Sales",
            field: "sales",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Planned Spends",
            field: "plannedSpends",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Spends",
            field: "spends",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Planned ROAS",
            field: "plannedRoas",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "ROAS",
            field: "roas",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          }
        ];
        return columns;
      } else if (this.type === "monthWiseDataAmazonDashboard") {
        let columns = [
          {
            label: "Month",
            field: "month",
            thClass: "campaign-name-th-class",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px"
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CTR",
            field: "ctr",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Spends",
            field: "spends",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CPC",
            field: "costPerClick",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Sales",
            field: "sales",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Orders",
            field: "orders",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CR",
            field: "conversionRate",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "ACOS",
            field: "acos",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "ROAS",
            field: "roas",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "AOV",
            field: "aov",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          }
        ];

        return columns;
      } else if (this.type === "facebook-daily-report") {
        if (this.businessType == 'leadgen') {
          let columns = [
          {
            label: "Date",
            field: "date",
            thClass: "campaign-name-th-class",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px"
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CTR",
            field: "ctr",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Spends",
            field: "spends",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CPC",
            field: "cpc",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CPR",
            field: "costPerResult",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          // {
          //   label: "Sales",
          //   field: "sales",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // },
          // {
          //   label: "Orders",
          //   field: "orders",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // },
          {
            label: "Leads",
            field: "conversions",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          // {
          //   label: "",
          //   field: "acos",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // },
          {
            label: "ROAS",
            field: "roas",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          }
          // {
          //   label: "AOV",
          //   field: "aov",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // }
        ];

        return columns;
        }else if(this.businessType == 'ecommerce'){
          let columns = [
          {
            label: "Date",
            field: "date",
            thClass: "campaign-name-th-class",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px"
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CTR",
            field: "ctr",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Spends",
            field: "spends",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CPC",
            field: "cpc",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CPR",
            field: "costPerResult",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          // {
          //   label: "Sales",
          //   field: "sales",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // },
          // {
          //   label: "Orders",
          //   field: "orders",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // },
          {
            label: "Purchases",
            field: "webPurchases",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          // {
          //   label: "",
          //   field: "acos",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // },
          {
            label: "ROAS",
            field: "roas",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          }
          // {
          //   label: "AOV",
          //   field: "aov",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // }
        ];

        return columns;
        }else{   let columns = [
          {
            label: "Date",
            field: "date",
            thClass: "campaign-name-th-class",
            tdClass: "text-center break-text",
            thClass: "text-center",
            width: "180px"
          },
          {
            label: "Impressions",
            field: "impressions",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Clicks",
            field: "clicks",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CTR",
            field: "ctr",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "Spends",
            field: "spends",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CPC",
            field: "cpc",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            label: "CPR",
            field: "costPerResult",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          // {
          //   label: "Sales",
          //   field: "sales",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // },
          // {
          //   label: "Orders",
          //   field: "orders",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // },
          {
            label: "Conversions",
            field: "conversions",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          },
          // {
          //   label: "",
          //   field: "acos",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // },
          {
            label: "ROAS",
            field: "roas",
            sortFn: this.sortFn,
            tdClass: "text-center",
            thClass: "text-center"
          }
          // {
          //   label: "AOV",
          //   field: "aov",
          //   sortFn: this.sortFn,
          //   tdClass: "text-center",
          //   thClass: "text-center"
          // }
        ];

        return columns;}
     
      }
    }
  },
  methods: {
    getSubCatergoryChange(val){
        this.$emit("subMonthCatergoryChange", val);
      },
      getSubSubCatergoryChange(val){
        this.$emit("subMonthSubCatergoryChange", val);
      },
      getBCGCatergoryChange(val){
        this.$emit("bCGMonthCatergoryChange", val);
      },
      getAdTypeCatergoryChange(val){
        this.$emit("subMonthAdTypeCatergoryChange", val);
      },

      getAmazonSubCatergoryChange(val){
        this.$emit("subAmazonMonthCatergoryChange", val);
      },
      getAmazonSubSubCatergoryChange(val){
        this.$emit("subAmazonMonthSubCatergoryChange", val);
      },
      getAmazonBCGCatergoryChange(val){
        this.$emit("bCGAmazonMonthCatergoryChange", val);
      },
      getAmazonAdTypeCatergoryChange(val){
        this.$emit("subAmazonMonthAdTypeCatergoryChange", val);
      },
    getFormattedText(text, field) {
      if (field === "clicks") {
        return millify(text);
      } else if (field === "impressions") {
        return millify(text);
      } else if (field === "costMicros") {
        return "₹ " + millify(text);
      } else if (field === "conversions") {
        return millify(text.toFixed(2));
      } else if (field === "ctr") {
        return (text * 100).toFixed(2) + "%";
      } else if (field === "avg cpm" || field === "avg_cpm") {
        return "₹ " + (text * 1000).toFixed(2);
      } else if (field === "avg cpc" || field === "avg_cpc") {
        return "₹ " + text.toFixed(2);
      } else if (field === "cpc") {
        return text.toFixed(2);
      } else {
        return text;
      }
    },
//      includeDateIntoData(array) {
//       return array.map(item => {
//         const { formattedData, ...rest } = item;
//         return {
//             date: item.date,
//             ...formattedData
//         };
//     });
// },
exportData() {
  // Determine the columns to display based on the channel type
  let displayArr = {}
  if (this.exportsType === 'googleCampaign') {
    displayArr = displayArr = {
      campaignName: "Campaign Name",
      costMicros: "Cost",
      impressions: "Impressions",
      clicks: "Clicks",
      conversions: "Leads",
      conversionRate: "Conversion Rate %",
      cpc: "CAC",
      ctr: "CTR",
      avg_cpm: "Avg. CPM",
      cpc: "Avg. CPC",
    }
  }
  if (this.exportsType === 'metaCampaign') {
    displayArr = displayArr = {
      campaignName: "Campaign Name",
      spends:"Spends",
      impressions: "Impressions",
      clicks: "Clicks",
      ctr: "CTR",
      conversions: "Leads",
      costPerResult: "Cost per Result",
      roas: "ROAS",
      cvr:"CVR"
    }
  }
  if (this.exportsType === 'amazonCampaign') {
    displayArr = displayArr = {
      key: "Product Name",
      impressions: "Impressions",
      clicks: "Clicks",
      spends:"Spends",
      sales:"Sales",
      orders:"Orders",
      ctr: "CTR",
      costPerClick: "CPC",
      conversionRate: "CVR",
      acos:"ACOS",
      roas: "ROAS",
      aov:"AOV"
    }
  }
  if (this.exportsType === 'googleDaily') {
    displayArr = displayArr = {
      date: "Date",
      costMicros: "Cost",
      impressions: "Impressions",
      clicks: "Clicks",
      conversions: "Leads",
      conversionRate: "Conversion Rate %",
      cpc: "CAC",
      ctr: "CTR",
      avg_cpm: "Avg. CPM",
      cpc: "Avg. CPC",
    }
  }
  if (this.exportsType === 'metaDaily') {
    displayArr = displayArr = {
      date: "Date",
      impressions: "Impressions",
      clicks: "Clicks",
      ctr: "CTR",
      spends: "Spends",
      cpc: "CPC",
      costPerResult: "CPR",
      conversions: "Leads",
      roas: "ROAS",
    }
  }
  if (this.exportsType === 'amazonDaily') {
    displayArr = displayArr = {
      key: "Date",
      impressions: "Impressions",
      clicks: "Clicks",
      spends: "Spends",
      sales:"Sales",
      orders:"Orders",
      ctr: "CTR",
      costPerClick: "CPC",
      conversionRate: "CVR",
      acos:"ACOS",
      roas: "ROAS",
      aov:"AOV"
    }
  }
  if (this.exportsType === 'amazonPlannedVsAchieved') {
    displayArr = displayArr = {
      key: "Date",
      plannedSales: "Planned Sales",
      sales:"Sales",
      plannedSpends: "Planned Spends",
      spends: "Spends",
      plannedRoas: "Planned ROAS",
      roas: "ROAS",
    }
  }
  if (this.exportsType === 'amazonMonthWise') {
    displayArr = displayArr = {
      month: "Month",
      impressions: "Impressions",
      clicks: "Clicks",
      ctr: "CTR",
      spends:"Spends",
      costPerClick: "CPC",
      sales:"Sales",
      orders:"Orders",
      conversionRate: "CR",
      acos:"ACOS",
      roas: "ROAS",
      aov:"AOV"
    }
  }
  // Helper function to escape and quote each value for CSV
  const escapeCSV = (str) => {
    if (str === null || str === undefined) {
      return '""';
    }
    return `"${String(str).replace(/"/g, '""')}"`;
  };

  // Generate CSV header based on the displayArr
  const header = Object.keys(displayArr)
    .map(field => escapeCSV(displayArr[field]))
    .join(",");

  // Generate CSV rows based on the displayArr
  const rows = this.chartData.map(item =>
    Object.keys(displayArr)
      .map(key => escapeCSV(item[key]))
      .join(",")
  );

  // Combine header and rows, separated by newline
  const csv = [header].concat(rows).join("\n");

  // Create a Blob for the CSV
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  // Create and trigger a temporary download link
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${this.type}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

,
  sortFn(a, b) {
    if (typeof a === 'string' && typeof b === 'string') {
      return sortFuntionStringintoNumericVal(a, b);
    } else {
      return sortFuntionNumericVal(a, b);
    }
  }
  },
  mounted() {
    this.currentPage = 1;
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.campaign-name-field {
  max-width: 200px;
}

.break-text {
  font-size: 12px;
  max-width: 150px;
  white-space: normal;
  overflow: visible;
}

.mainTable {
  padding: 6px !important;
}
.screenAlignmentDataTable {
  .card-body {
    padding: 0.6rem !important;
  }
}
.schedule-hearder {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
}
input {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 210px !important;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
.exportButton{
  height: 38px !important;
}
.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  /* Set the height of the container */
}
</style>
