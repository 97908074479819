<template>
  <b-card no-body class="card-statistics">
    <!-- <b-card-header>
      <b-card-title>Statistics</b-card-title>
    </b-card-header> -->
    <b-card-body class="statistics-body"> 
      <b-row v-if="statisticsItemsData.length === 0" class="iconCentered">
         <b-col cols="12"><div><h5>No Data Found.</h5><br/><span style="color: brown;">*{{ adRunningError }}</span></div></b-col>
      </b-row>
      <b-row v-else> 
        <b-col
          v-for="item in statisticsItemsData"
          :key="item.subtitle"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <div class="text-center mb-1">
            <b-avatar size="48" :variant="item.color">
              <span
                v-if="item.icon == 'currency-rupee'"
                style="font-size: 22px;"
              >
                ₹</span
              >
              <span v-else><feather-icon size="24" :icon="item.icon"/></span>
            </b-avatar>
          </div>
          <b-media class="text-center" no-body>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import commaNumber from "comma-number";
import millify from "millify";
import projectsMap from "@/@core/utils/projectDetailMap";
import { f } from "vue-phone-number-input/dist/vue-phone-number-input.common";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    iconType:{
      type:String
    },
    businessType:{
      type:Array
    },
    adRunningError:{type:String},
    summaryData: {
      type: Object,
      default: () => {
        return {
          costMicros: 0,
          impressions: 0,
          clicks: 0,
          cpc:0,
          ctr:0,
          cpcl:0,
          conversions: 0,
          costPerConversion:0,
          conversionValue:0,
          ROAS:0,
        };
      },
    },
  },
  computed: {
    statisticsItemsData() {
        let statisticsItems = [];
let summaryData = this.summaryData;

// let order = [ 'spends', 'sales','orders','impressions', 'clicks'];
const order = ["costMicros", "impressions", "clicks", "conversions", "cpl","cpcl","ctr", "ROAS","cpc", "purchases", "roas", "conversion value","cpm", "cvr"];

for (let i = 0; i < order.length; i++) {
  const dataKey = order[i];
  let title = dataKey;
  title = title.replace("_", " ").toLowerCase();
  if (summaryData[dataKey] !== undefined) {
    let dataToPush = this.getFormattedText(summaryData[dataKey], title);
    if (dataToPush) {
      statisticsItems.push(dataToPush);
    }
  }
}

return statisticsItems;
      },
  },
  data() {
    return {
      projectsMap:projectsMap
    };
  },
  methods: {
  getFormattedText(text, field) {
  field = field.toLowerCase();
   if (field === "clicks") {
    return {
      icon: "MousePointerIcon",
      color: "light-warning",
      title: millify(text),
      subtitle: "Clicks",
      customClass: "mb-2 mb-xl-0",
    };
  } 
 else if (field === "costmicros") {
    return {
      icon: "currency-rupee",
      color: "light-warning",
      title: millify(text),
      subtitle: "Spends",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "leads" && this.businessType[0] == 'leadgen') {
    return {
      icon: "currency-rupee",
      color: "light-info",
      title: millify(text),
      subtitle: "Leads",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "conversions" && this.businessType[0] == 'ecommerce') {
    return {
      icon: "currency-rupee",
      color: "light-info",
      title: millify(text),
      subtitle: "Purchases",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "cpl" && this.businessType[0] == 'leadgen') {
    return {
      icon: "MousePointerIcon",
      color: "light-warning",
      title: millify(text),
      subtitle: "CPL",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "impressions") {
    return {
      icon: "EyeIcon",
      color: "light-info",
      title: millify(text),
      subtitle: "Impressions",
      customClass: "mb-2 mb-xl-0",
    };
  } else if (field === "ctr") {
    return {
      icon: "MousePointerIcon",
      color: "light-warning",
      title: millify(text),
      subtitle: "CTR%",
      customClass: "mb-2 mb-xl-0",
    };
  } else if (field === "cpcl") {
    return {
      icon: "EyeIcon",
      color: "light-pink",
      title: millify(text),
      subtitle: "CPC",
      customClass: "mb-2 mb-xl-0",
    };
  } else if (field === "conversions" && this.businessType[0] == 'leadgen') {
    return {
      icon: "MousePointerIcon",
      color: "light-warning",
      title: millify(text),
      subtitle: "Leads",
      customClass: "mb-2 mb-xl-0",
    };
  } else if (field === "costmicros" || field === "conversions") {
    return {
      icon: "TargetIcon",
      color: "light-warning",
      title: millify(text),
      subtitle: this.businessType[0] == 'leadgen' ? 'Leads' : 'Conversions',
      customClass: "mb-2 mb-xl-0",
    };
  } else if (field === "roas" && this.businessType[0] !== 'leadgen') {
    return {
      icon: "TargetIcon",
      color: "light-pink",
      title: millify(text),
      subtitle: "ROAS",
      customClass: "mb-2 mb-xl-0",
    };
  }
},

  },
};
</script>

<style>
.iconCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

</style>